import '../styles/style.scss';
import 'slick-carousel';

import Inputmask from 'inputmask';

import GG, {
	ggPopup,
	ggAutoCurrent,
	ggResponsive,
	ggFormsController,
	formatNumberWithSpaces
} from 'ggcore';

const init = {
	config: {
		log: true
	},
	formatNumberWithSpaces
};

GG.use(
	init,
	ggPopup,
	ggAutoCurrent,
	ggResponsive,
	ggFormsController
);




window.GG = GG;


function requireAll(r) {
	r.keys().forEach(r);
}

// Inject templates only in development mode (for hot-reload)
if (process.env.NODE_ENV === 'development') {
	requireAll(require.context('../', true, /\.pug/));
}


$(function () {
	if ($('.form__input_phone').length) {
		Inputmask({mask: '+7 (999) 999-99-99'}).mask(document.querySelectorAll('.form__input_phone'));
	}

	(function scrollMenu() {
		if ($(window).scrollTop() > 0) {
			$('.header').addClass('header_scroll');
		} else {
			$('.header').removeClass('header_scroll');
		}
		$(window).scroll(function () {
			if ($(window).scrollTop() > 0) {
				$('.header').addClass('header_scroll');
			} else {
				$('.header').removeClass('header_scroll');
			}
		});
	})();

	if ($('.header__menu .menu__entry .submenu').length) {
		$('.submenu')
			.closest('.menu__entry')
			.addClass('menu__entry_non-empty');
	}

	$('.header__contacts .contacts__entry_address').click(function (e) {
		e.preventDefault();
		GG.togglePopup('popup-contacts');
	});

	$('.btn-request').click(function (e) {
		e.preventDefault();

		GG.togglePopup('popup-request');
	});

	//$('.form__label-personal a').click(function (e) {
	//	e.preventDefault();
	//	var footerOffset = $('.footer').offset().top;
	//
	//	if($(this).closest('.popup')) {
	//		var popupID = $(this).closest('.popup').attr('id');
	//		GG.togglePopup(popupID);
	//	}
	//
	//	setTimeout(function () {
	//		$('html, body').animate({scrollTop: footerOffset}, 500);
	//	}, 300);
	//});

	$('.rules__lnk').click(function (e) {
		e.preventDefault();

		if(GG.config.responsive) {
			$(this).next('.rules__tooltip').toggle();
		}
	});

	//the plavayuschaya red huibola in menu!
	function animateCurrMenuItem(el) {
		let $currEl = $('.menu-curr');
		let currentMenuPos = el.position();
		let currentMenuWidth = el.find('.menu__lnk').css('width');
		let additionalOffset = $('.header__menu .menu__entry:last-child').css('padding-left');
		let posleft = el.is('.header__menu .menu__entry:first-child') ? currentMenuPos.left : currentMenuPos.left + parseInt(additionalOffset);
		$currEl.css({'left': (posleft - 3) + 'px', 'width': currentMenuWidth})
		if($(window).width() < 1380) {
			$currEl.css({'left': (posleft - 7) + 'px', 'width': currentMenuWidth})
		} else {
			$currEl.css({'left': (posleft - 3) + 'px', 'width': currentMenuWidth})
		}
	}

	if(!GG.config.responsive) {

		if(!$('.mainpage').length && $('.header__menu .menu__entry_current').length) {
			animateCurrMenuItem($('.header__menu .menu__entry_current'));
		$('.header__menu .menu__entry').hover(function () {
			animateCurrMenuItem($(this));
		}, function () {
			animateCurrMenuItem($('.header__menu .menu__entry_current'));
		});
		} else {
			$('.header__menu .menu__entry').hover(function () {
				animateCurrMenuItem($(this));
			});
		}
	}

	if($('.advantages__list').length) {
		$('.advantages__list').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			dots: true,
			swipe: false,
			prevArrow: '<button type="button" class="slick-prev"><svg class="arr_red" version="1.1" width="9" height="17" viewBox="0 0 9 17"><path fill="#D8232A" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg><svg class="arr_white" version="1.1" x="0px" y="0px" viewBox="0 0 9 17" width="9" height="17"><path fill="#FFFFFF" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg></button>',
			nextArrow: '<button type="button" class="slick-next"><svg class="arr_red" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#D8232A" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg><svg class="arr_white" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#ffffff" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg></button>',
			rows: 0,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: false,
						dots: true,
						swipe: true
					}
				}, {
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						dots: true,
						swipe: true
					}
				}
			]
		})
	}

	if ($('.partners').length) {
		$('.partners__list').slick({
			slidesToShow: 4,
			slidesToScroll: 2,
			infinite: true,
			dots: true,
			prevArrow: '<button type="button" class="slick-prev"><svg class="arr_red" version="1.1" width="9" height="17" viewBox="0 0 9 17"><path fill="#D8232A" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg><svg class="arr_white" version="1.1" x="0px" y="0px" viewBox="0 0 9 17" width="9" height="17"><path fill="#FFFFFF" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg></button>',
			nextArrow: '<button type="button" class="slick-next"><svg class="arr_red" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#D8232A" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg><svg class="arr_white" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#ffffff" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg></button>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: true,
						dots: false
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						dots: false
					}
				}
			]
		});
	}

	if($('.page-head').length) {
		var classes = ['green', 'blue'];
		var randomnumber = Math.floor(Math.random()*classes.length);

		$('.page-head').addClass(classes[randomnumber]);
	}

	if($('#main-contacts-map').length) {
		if (typeof ymaps != 'undefined') {
			ymaps.ready(YMapsInitMain);
		}
	}

	if($('#popup-contacts-map').length) {
		if (typeof ymaps != 'undefined') {
			ymaps.ready(YMapsInitPopup);
		}
	}

	// Yandex Maps
	var mainContactsMap, mainContactsPlacemark;

	function YMapsInitMain() {
		var zoom = GG.config.responsive ? 15 : 16;

		mainContactsMap = new ymaps.Map('main-contacts-map', {
			center: [59.929168, 30.366231],
			zoom: zoom
		});

		mainContactsPlacemark = new ymaps.Placemark([59.927863, 30.371389], {
			hintContent: 'Санкт-Петербург, ул. Полтавская, д.6'
		}, {
			iconImageHref: '/assets/dist/img/pin.svg',
			iconLayout: 'default#image',
			iconImageSize: [35, 60],
			iconImageOffset: [-20, -60]
		});

		if (GG.config.responsive) {
			mainContactsMap.behaviors.disable('scrollZoom');
			mainContactsMap.behaviors.enable('multiTouch');
		}

		mainContactsMap.geoObjects.add(mainContactsPlacemark);
	}

	var popupContactsMap, popupContactsPlacemark;

	function YMapsInitPopup() {

		var zoom = GG.config.responsive ? 15 : 16;
		popupContactsMap = new ymaps.Map('popup-contacts-map', {
			center: [59.929661, 30.367491],
			zoom: zoom
		});

		popupContactsPlacemark = new ymaps.Placemark([59.927863, 30.371389], {
			hintContent: 'Санкт-Петербург, ул. Полтавская, д.6'
		}, {
			iconImageHref: '/assets/dist/img/pin.svg',
			iconLayout: 'default#image',
			iconImageSize: [35, 60],
			iconImageOffset: [-20, -60]
		});

		if (GG.config.responsive) {
			popupContactsMap.behaviors.disable('scrollZoom');
			popupContactsMap.behaviors.enable('multiTouch');
		}

		popupContactsMap.geoObjects.add(popupContactsPlacemark);
	}

	//parallax
	if ($('.banners_bg_1').length) $('.banners_bg_1').parallax("60% ", 1.2);
	if ($('.banners_bg_2').length) $('.banners_bg_2').parallax("80% ", 0.6);
	if ($('.notfound_bg_1').length) $('.notfound_bg_1').parallax("60% ", 0.6);
	if ($('.notfound_bg_2').length) $('.banners_bg_2').parallax("80% ", 0.6);
	if ($('.main-form_bg').length) $('.main-form_bg').parallax("30% ", 0.6);


	if(GG.config.responsive) {
		$('.js-header-toggle').click(function (e) {
			e.preventDefault();

			$('.header__container').toggleClass('opened');
		});

		$('html, body').animate({scrollTop: 0}, 0);
	}
});

//parallax plugin
(function( $ ){
	var $window = $(window);
	var windowHeight = $window.height();

	$window.resize(function () {
		windowHeight = $window.height();
	});

	$.fn.parallax = function(xpos, speedFactor, outerHeight) {
		var $this = $(this);
		var getHeight;
		var firstTop;
		var paddingTop = 0;

		//get the starting position of each element to have parallax applied to it
		$this.each(function(){
			firstTop = $this.offset().top;
		});

		if (outerHeight) {
			getHeight = function(jqo) {
				return jqo.outerHeight(true);
			};
		} else {
			getHeight = function(jqo) {
				return jqo.height();
			};
		}

		// setup defaults if arguments aren't specified
		if (arguments.length < 1 || xpos === null) xpos = "50%";
		if (arguments.length < 2 || speedFactor === null) speedFactor = 0.1;
		if (arguments.length < 3 || outerHeight === null) outerHeight = true;

		// function to be called whenever the window is scrolled or resized
		function update(){
			var pos = $window.scrollTop();

			$this.each(function(){
				var $element = $(this);
				var top = $element.offset().top;
				var height = getHeight($element);

				// Check if totally above or totally below viewport
				if (top + height < pos || top > pos + windowHeight) {
					return;
				}

				$this.css('backgroundPosition', xpos + " " + Math.round((firstTop - pos) * speedFactor) + "px");
			});
		}

		$window.bind('scroll', update).resize(update);
		update();
	};
})(jQuery);
